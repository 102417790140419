<template>
    <section class="pt-3">
        <div class="d-flex mx-0">
            <div class="col-auto px-0 mt-2">
                <div class="btn-back" @click="$router.push({name: 'admin.tesoreria.comisiones-pagadas'})">
                    <i class="icon-left f-14" />
                </div>
            </div>
            <div class="col">
                <div class="row mx-0 bg-white py-2">
                    <div class="col-auto">
                        <div class="d-middle">
                            <img :src="comision.img_encargado" alt="" class="wh-46 rounded-circle" />
                            <div class="text-general ml-3">
                                <p class="f-20 f-500">{{ comision.encargado }}</p>
                                <p class="f-14">Encargado del pago - {{comision.created_at | helper-fecha('DD MMM Y, h:mm a')}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto" />
                </div>
                <div class="w-100">
                    <tabla-general :data="comision.vendedores" alto="calc(100vh - 365px)" class-header="text-general f-16" @filaFuncion="openDetalle">
                        <template slot="cabecera-izquierda">
                            <div class="col-auto px-4 py-3 text-general f-18 ">
                                <p class="f-600">{{ comision.vendedores.length }} Pedidos comisionados</p>
                                <p class="f-14">Bucaramanga</p>
                            </div>
                        </template>
                        <el-table-column label="Vendedor">
                            <template slot-scope="{row}">
                                <div class="d-middle">
                                    <img :src="row.img_tienda" alt="" class="wh-46 rounded-circle" />
                                    <p class="f-16 text-general ml-2">{{ row.tendero_nombre }}</p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="cant_pedidos" label="Pedidos" align="center" />
                        <el-table-column label="Comisión" align="center">
                            <template slot-scope="{row}">
                                <p class="text-general text-center">{{ convertMoney(row.valor_pagado,row.idm_moneda)  }}</p>
                            </template>
                        </el-table-column>
                    </tabla-general>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-detalle-comision ref="refModalDetalleComision" :tendero="vendedor" :encargado="{nombre: comision.encargado, fecha: comision.created_at, img: comision.img_encargado }" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from 'moment'
import Vue from 'vue'
import VueExcelXlsx from "vue-excel-xlsx";
import ModalDetalleComision from './partials/modalDetalleComision.vue';
import Comisiones from '~/services/comisiones/comisiones_pagadas';
Vue.use(VueExcelXlsx)

export default {
    name: 'ComisionPagadasLlandasDetalle',
    components: {
        ModalDetalleComision
    },
    data(){
        return{
            dataTable: [],
			vendedor: {},
            registros: [],
			comision: {},
			idComision: this.$route.params.id,
            dataTable2:[
                {
                    id: 1,
                    vendedor: 'Jose Manuel Trujillo',
                    imagen: '/img/no-imagen/avatar.png',
                    pedidos: 5,
                    valor: 5734748.00,
                    idm_moneda: 1,
                },
                {
                    id: 1,
                    vendedor: 'Katalina Marina Villamizar Calderón',
                    imagen: '/img/no-imagen/avatar.png',
                    pedidos: 0,
                    valor: 5734748.00,
                    idm_moneda: 1,
                },
                {
                    id: 1,
                    vendedor: 'Olga Celis',
                    imagen: '/img/no-imagen/avatar.png',
                    pedidos: 3,
                    valor: 5734748.00,
                    idm_moneda: 1,
                },
            ],
            //
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis'
        }),
    },
    watch:{
        // id_cedis(val){
        //     this.getTabla()
        // }
    },
    mounted(){
        // if(this.id_cedis != null){
            this.getTabla()
        // }
    },
    methods: {
        async getTabla(){
            try {
                const {data} = await Comisiones.getDetail(this.$route.params.id)
				this.comision = data.data;
            } catch (e){
                this.error_catch(e)
            }
        },
        openDetalle(row){
			this.vendedor.nombre = row.tendero_nombre
			this.vendedor.total = row.valor_pagado
			this.vendedor.img = row.img_tienda
            this.$refs.refModalDetalleComision.toggle(this.$route.params.id, row.id_tienda)
        },
    }
}
</script>
<style scoped>
.boton-excel {
    border: 0px;
    background: transparent;
    color: #fff;
}
</style>
