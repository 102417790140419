<template>
    <modal ref="modalDetallePago" titulo="Detalle de comisión" tamano="modal-xl" no-cancelar no-aceptar>
        <div class="row mx-0 justify-content-center align-items-center">
            <img :src="tendero.img" width="46" height="46" class="rounded-circle border" />
            <div class="col-auto">
                <p class="text-general f-20 f-500">
                    {{ tendero.nombre }}
                </p>
                <p class="text-general f-14 fr-light">
                    <!-- {{ vendedor.cedis_nombre }} -->
                </p>
            </div>
            <div class="col-3 text-center text-general">
                <p class="f-20"> <b>{{ separadorNumero(tendero.total) }}</b></p>
                <p class="fr-light">
                    <i class="icon-comision f-14 text-general mr-1" />
                    Comisión total
                </p>
            </div>
            <img :src="encargado.img" width="46" height="46" class="rounded-circle border" />
            <div class="col-auto">
                <p class="text-general f-20 f-500">
                    {{ encargado.nombre }}
                </p>
                <p class="text-general f-13">
                    Encargado del pago - {{ encargado.fecha | helper-fecha('DD-MMM-Y') }}
                </p>
            </div>
        </div>
        <div class="row mx-0 pt-4">
            <div class="col-12">
                <div class=" custom-scroll overflow-auto">
                    <tabla-general :data="pedidos" alto="350" :usar-paginacion="false" class-header="text-general" style="width: 100%">
                        <template slot="cabecera-izquierda">
                            <div class="col-auto px-4 py-3 text-general f-18 f-600">
                                {{ pedidos.length }} Pedidos comisionados
                            </div>
                        </template>
                        <el-table-column prop="id" label="Pedido" class-name="text-center text-general" />
                        <el-table-column label="Fecha Pedido" align="center" min-width="150">
                            <template slot-scope="{row}">
                                <p class="text-general text-center">{{ row.created_at | helper-fecha('DD MMM Y') }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Valor Pedido" align="center" min-width="150">
                            <template slot-scope="{row}">
                                <p class="text-general text-center">{{ separadorNumero(row.valor_final) }}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="Comisión" align="center" min-width="150">
                            <template slot-scope="{row}">
                                <p class="text-general text-center">{{ separadorNumero(row.valor_comision) }}</p>
                            </template>
                        </el-table-column>
                    </tabla-general>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Comisiones from '~/services/comisiones/comisiones_pagadas';
export default {
	 props: {
        encargado: {
            type: Object,
            default: () => {
                return {
                    nombre: '',
                    fecha: 0,
                    img: ''
                }
            }
        },
        tendero: {
            type: Object,
            default: () => {
                return {
                    nombre: '',
                    total: 0,
                }
            }
        }
    },
    data(){
        return {
            buscar: '',
            alto_tabla: 'calc(100vh - 592px)',
            pedidos:[],
        }
    },
    methods: {
        toggle(id, idTienda){
            this.detallePago(id, idTienda)
            this.$refs.modalDetallePago.toggle();
        },
        async detallePago(id, idTienda){
            try {
                const {data} = await Comisiones.getPedidos(id, idTienda)
                this.pedidos = data.data
            } catch (e){
                this.error_catch(e)
            }
        },


    }
}
</script>
