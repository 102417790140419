import axios from 'axios'

const API = 'comisiones-pagadas'

const Liquidacion = {
	getComisiones(params) {
		return axios.get(`${API}`, { params })
	},
	getDetail(idComision) {
		return axios.get(`${API}/${idComision}/detalle`)
	},
	getPedidos(idComision, idTienda) {
		return axios.get(`${API}/${idComision}/tienda/${idTienda}/pedidos`)
	},
	getComisionesVigentesTendero(params) {
		return axios.get(`vendedor/comisiones/pendientes`, { params })
	},
	getComisionesFinalizadasTendero(params) {
		return axios.get(`vendedor/comisiones/pagadas`, { params })
	},
}

export default Liquidacion
